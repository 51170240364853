/* global google */
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import GoogleMap from "../components/GoogleMap";
import DeliveryExperience from "../components/DeliveryExperience";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultLogo from "../assets/images/edglogo.png";
import { faHouseUser, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import "../styles/TrackingPage.css";

const TrackingPage = () => {
  const [liveLocation, setLiveLocation] = useState(null);
  const [previousLocation, setPreviousLocation] = useState(null);
  let { jobId } = useParams();
  const [estpath, setEstPath] = useState([]);
  const [arrivalTime, setArrivalTime] = useState("");
  const [distanceRemaining, setDistanceRemaining] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [trackingId, setTrackingId] = useState(null);
  const [sellerLogo, setSellerLogo] = useState(null);
  const [rating, setRating] = useState(0);
  const [status, setStatus] = useState(null);
  const [pickUpLocation, setPickUpLocation] = useState(null);
  const [dropLocation, setDropLocation] = useState(null);
  const [error, setError] = useState(null);
  const [isFinalPath, setIsFinalPath] = useState(false);
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [fetchedEtaAndDistance, setFetchedEtaAndDistance] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState("");

  const showEtaAndDistance = ![
    "DELIVERED",
    "RESCHEDULED",
    "CANCELLED",
  ].includes(status);

  const generateMapUrl = (coordinates) => {
    return `https://www.google.com/maps/search/?api=1&query=${coordinates.lat},${coordinates.lng}`;
  };

  const calculateArrivalTime = (eta) => {
    const etaMinutes = parseInt(eta.split(" ")[0]);
    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + etaMinutes);

    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const dateOptions = { day: "numeric", month: "short", year: "numeric" };

    const timeString = currentTime.toLocaleTimeString([], timeOptions);
    const dateString = currentTime
      .toLocaleDateString([], dateOptions)
      .replace(/(\d+)(st|nd|rd|th)/, "$1");

    return `${timeString}, ${dateString}`;
  };

  const fetchEtaAndDistance = useCallback(
    async (liveLocation, status) => {
      if (liveLocation && (pickUpLocation || dropLocation)) {
        const origins = `${liveLocation.lat},${liveLocation.lng}`;
        let destinations;
        if (
          [
            "NAVIGATE_TO_PICKUP_LOCATION",
            "PICKUP_IN_PROGRESS",
            "INSIDE_PICKUP_GEOFENCE",
            "REACHED_PICKUP_LOCATION",
          ].includes(status)
        ) {
          destinations = `${pickUpLocation.lat},${pickUpLocation.lng}`;
        } else if (
          [
            "NAVIGATE_TO_DROP_LOCATION",
            "INSIDE_DROP_GEOFENCE",
            "REACHED_DROP_LOCATION",
            "DROP_IN_PROGRESS",
            "REASSIGNED",
          ].includes(status)
        ) {
          destinations = `${dropLocation.lat},${dropLocation.lng}`;
        } else {
          // Handle cases where status does not match any of the predefined statuses
          console.log("Status does not require navigation updates.");
          return;
        }

        try {
          const response = await fetch(
            `${
              process.env.REACT_APP_API_BASE_URL
            }/order/rider/job/distance?origin=${encodeURIComponent(
              origins
            )}&destination=${encodeURIComponent(destinations)}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();

          if (data.data.rows && data.data.rows[0].elements[0].status === "OK") {
            // Extracting distance and duration from the response
            const distanceText = data.data.rows[0].elements[0].distance.text;
            const durationText =
              data.data.rows[0].elements[0].duration_in_traffic.text;

            const newArrivalTime = calculateArrivalTime(durationText);

            setDistanceRemaining(distanceText);
            setArrivalTime(newArrivalTime);
          } else {
            console.log("Google API response error:", data);
          }
          const directionsResponse = await fetch(
            `${
              process.env.REACT_APP_API_BASE_URL
            }/order/rider/job/directions?origin=${encodeURIComponent(
              origins
            )}&destination=${encodeURIComponent(destinations)}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const directionsData = await directionsResponse.json();

          if (directionsData.data && directionsData.data.points) {
            setEstPath([]);
            const estpath = google.maps.geometry.encoding.decodePath(
              directionsData.data.points
            );
            setEstPath(estpath);
          }
        } catch (error) {
          console.error("Error fetching ETA and distance:", error);
        }
      }
    },
    [pickUpLocation, dropLocation]
  );

  const fetchLiveLocation = useCallback(async () => {
    try {
      const reqURL = `${process.env.REACT_APP_API_BASE_URL}/order/live-tracking?jobId=${jobId}`;
      const response = await fetch(reqURL);
      const data = await response.json();

      if (data.success && data.data.length > 0) {
        const trackingData = data.data[0];

        setOrderId(trackingData.orderRefId);
        setTrackingId(trackingData.awbNumber);
        setStatus(trackingData.status);
        setSellerLogo(trackingData.seller.logo);
        setRating(trackingData.rating);
        const pickupCord = {
          lat: trackingData.pickUpLocation.coordinates[0],
          lng: trackingData.pickUpLocation.coordinates[1],
        };


        const dropCord = {
          lat: trackingData.dropLocation.coordinates[0],
          lng: trackingData.dropLocation.coordinates[1],
        };

        setPreviousLocation(liveLocation);
        const newLocation = {
          lat: trackingData.liveLocation.coordinates[0],
          lng: trackingData.liveLocation.coordinates[1],
        };

        setLiveLocation(newLocation);
        setPickUpLocation(pickupCord);
        setDropLocation(dropCord);
        setStatus(trackingData.status);

        if (
          ["DELIVERED", "RESCHEDULED", "CANCELLED"].includes(
            trackingData.status
          )
        ) {
          return;
        }
        if (!initialDataFetched) {
          setInitialDataFetched(true); // Set the flag
        }
      }
    } catch (error) {
      console.error("Error fetching live location:", error);
      setError("Unable to fetch live location. Please check your connection.");
    }
  }, [jobId]);

  useEffect(() => {
    fetchLiveLocation();
    const intervalId = setInterval(fetchLiveLocation, 10000); 
    return () => clearInterval(intervalId);
  }, [fetchLiveLocation]);

  useEffect(() => {
    // Conditional call to fetchEtaAndDistance
    if (
      !fetchedEtaAndDistance &&
      initialDataFetched &&
      liveLocation &&
      pickUpLocation &&
      dropLocation &&
      status
    ) {
      fetchEtaAndDistance(liveLocation, status);
      setFetchedEtaAndDistance(true); // Prevent future calls
    }
  }, [
    initialDataFetched,
    liveLocation,
    pickUpLocation,
    dropLocation,
    status,
    fetchedEtaAndDistance,
    fetchEtaAndDistance,
  ]);

  useEffect(() => {
    const fetchDirectionsForFinalPath = async () => {
      if (
        ["DELIVERED", "RESCHEDULED", "CANCELLED"].includes(status) &&
        pickUpLocation &&
        dropLocation
      ) {
        const origin = `${pickUpLocation.lat},${pickUpLocation.lng}`;
        const destination = `${dropLocation.lat},${dropLocation.lng}`;

        try {
          const response = await fetch(
            `${
              process.env.REACT_APP_API_BASE_URL
            }/order/rider/job/directions?origin=${encodeURIComponent(
              origin
            )}&destination=${encodeURIComponent(destination)}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          if (data.data && data.data.points) {
            const path = google.maps.geometry.encoding.decodePath(
              data.data.points
            );
            setEstPath(path);
            setIsFinalPath(true);
          }
        } catch (error) {
          console.error("Error fetching directions:", error);
        }
      }
    };

    fetchDirectionsForFinalPath();
  }, [status, pickUpLocation, dropLocation]);

  const updateTimeRemaining = useCallback(() => {
    if (arrivalTime) {
      const arrivalDate = new Date(arrivalTime);
      const currentDate = new Date();
      const timeDiff = arrivalDate - currentDate;

      if (timeDiff > 0) {
        // Calculate hours, minutes, and seconds
        const hours = Math.floor(timeDiff / 3600000);
        const minutes = Math.floor((timeDiff % 3600000) / 60000);
        const seconds = Math.floor((timeDiff % 60000) / 1000);

        setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeRemaining("");
      }
    }
  }, [arrivalTime]);

  useEffect(() => {
    const intervalId = setInterval(updateTimeRemaining, 1000);
    return () => clearInterval(intervalId);
  }, [updateTimeRemaining]);

  return (
    <div className="tracking-page">
      {sellerLogo && (
        <p>
          <img
            src={sellerLogo || defaultLogo}
            alt="Seller Logo"
            className="company-logo"
          />
        </p>
      )}
      <h1 className="heading">EdgFMS Live Tracking</h1>
      {error && <div className="error-message">{error}</div>}
      <div className="order-info">
        <div className="order-info-column">
          <p>
            Order ID: <span className="info-bold">{orderId}</span>
          </p>
          <p>
            Tracking ID: <span className="info-bold">{trackingId}</span>
          </p>
          <p>
            Pickup Point:{" "}
            <a
              href={pickUpLocation ? generateMapUrl(pickUpLocation) : "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faWarehouse} className="my-icon" />
            </a>
          </p>
          <p>
            Drop Point:{" "}
            <a
              href={dropLocation ? generateMapUrl(dropLocation) : "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faHouseUser} className="my-icon" />
            </a>
          </p>
        </div>
        <div className="order-info-column">
          <p>
            Current Status:
            <span
              className="info-bold"
              style={{
                color:
                  status === "DELIVERED"
                    ? "darkgreen"
                    : status === "CANCELLED"
                    ? "darkred"
                    : "inherit", // Default text color
              }}
            >
              {status}
            </span>
          </p>
          {showEtaAndDistance && (
            <>
              <p>
                ETA (in mins): <span className="info-bold">{arrivalTime}</span>
              </p>
              {/* <p>
                Arrival Time:{" "}
                <span className="info-bold"> {timeRemaining}</span>
              </p> */}
              <p>
                Distance Remaining (in km):{" "}
                <span className="info-bold">{distanceRemaining}</span>
              </p>
            </>
          )}
        </div>
      </div>
      <div className="map-container">
        <GoogleMap
          liveLocation={liveLocation}
          pickupLocation={pickUpLocation}
          dropLocation={dropLocation}
          estpath={estpath}
          isFinalPath={isFinalPath}
          previousLocation={previousLocation}
          status={status}
        />
      </div>
      {status === "DELIVERED" && (
        <>
          <DeliveryExperience jobId={jobId} rating={rating} />
        </>
      )}
      <h2 className="title">
        Powered by{" "}
        <a
          href="https://edgistify.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline"
        >
          Edgistify
        </a>
      </h2>
    </div>
  );
};

export default TrackingPage;