import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScriptNext,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import bikeIconUrl from "../assets/icons/rider_maple.png";
import drop_Icon from "../assets/icons/pick_Icon.png";
import pick_Icon from "../assets/icons/pick_Icon.png";


const containerStyle = {
  width: "95%",
  height: "500px",
};

const libraries = ["geometry"];

const MyGoogleMap = ({
  liveLocation,
  pickupLocation,
  dropLocation,
  estpath,
  isFinalPath,
  previousLocation,
  status,
}) => {
  const [mapCenter, setMapCenter] = useState(
    isFinalPath
      ? dropLocation
      : liveLocation || { lat: 19.1905823, lng: 72.9550549 }
  );
  const [heading, setHeading] = useState(0);

  useEffect(() => {
    if (previousLocation && liveLocation && window.google) {
      const headingValue = window.google.maps.geometry.spherical.computeHeading(
        new window.google.maps.LatLng(
          previousLocation.lat,
          previousLocation.lng
        ),
        new window.google.maps.LatLng(liveLocation.lat, liveLocation.lng)
      );
      setHeading(headingValue);
    }
  }, [previousLocation, liveLocation]);

  useEffect(() => {
    if (isFinalPath) {
      setMapCenter(dropLocation);
    } else if (liveLocation) {
      setMapCenter(liveLocation);
    }
  }, [liveLocation, isFinalPath, dropLocation]);

  const polylineOptions = isFinalPath
    ? {
        strokeColor: "#00A699",
        strokeWeight: 2,
      }
    : {
        strokeColor: "#c90404",
        strokeWeight: 2,
      };

      const navigatingToPickupStatuses = [
        "NAVIGATE_TO_PICKUP_LOCATION",
        "PICKUP_IN_PROGRESS",
        "INSIDE_PICKUP_GEOFENCE",
        "REACHED_PICKUP_LOCATION",
        "DELIVERED",
      ];

      const navigatingToDropStatuses = [
        "NAVIGATE_TO_DROP_LOCATION",
        "INSIDE_DROP_GEOFENCE",
        "REACHED_DROP_LOCATION",
        "DROP_IN_PROGRESS",
        "DELIVERED",
      ];

  return (
    <LoadScriptNext
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={18}
        >
          {navigatingToPickupStatuses.includes(status) && pickupLocation && (
            <Marker
              position={{ lat: pickupLocation.lat, lng: pickupLocation.lng }}
              icon={pick_Icon}
            />
          )}
          {!isFinalPath && liveLocation && (
            <Marker
              position={{ lat: liveLocation.lat, lng: liveLocation.lng }}
              icon={{
                url: bikeIconUrl,
                scaledSize:
                  window.google && new window.google.maps.Size(80, 80),
                rotation: heading,
              }}
            />
          )}
          {navigatingToDropStatuses.includes(status) && dropLocation && (
            <Marker
              position={{ lat: dropLocation.lat, lng: dropLocation.lng }}
              icon={drop_Icon}
            />
          )}
          {estpath && estpath.length > 0 && (
            <Polyline path={estpath} options={polylineOptions} />
          )}
        </GoogleMap>
      </div>
    </LoadScriptNext>
  );
};

export default MyGoogleMap;