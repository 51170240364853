import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TrackingPage from "./pages/TrackingPage";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Define your routes within Routes component */}
          <Route path="/:jobId" element={<TrackingPage />} />

          {/* Optional: Add more routes here */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
