import React, { useEffect, useState } from "react";
import "../styles/DeliveryExperience.css";

const DeliveryExperience = ({ jobId, rating }) => {
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [isSelectionMade, setIsSelectionMade] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const experiences = [
    { label: "TERRIBLE", emoji: "😠", rating: 1 },
    { label: "BAD", emoji: "😦", rating: 2 },
    { label: "OKAY", emoji: "😐", rating: 3 },
    { label: "GOOD", emoji: "🙂", rating: 4 },
    { label: "EXCELLENT", emoji: "😀", rating: 5 },
  ];

  useEffect(() => {
    if (rating > 0) {
      const foundExperience = experiences.find((exp) => exp.rating === rating);
      if (foundExperience) {
        setSelectedExperience(foundExperience.label);
        setIsSelectionMade(true);
      }
    }
  }, [rating]);

  const handleExperienceClick = async (experience) => {
    if (isSelectionMade) return;

    setSelectedExperience(experience.label);
    setIsSelectionMade(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/order/rider/job/feedback?jobId=${jobId}&rating=${experience.rating}`,
        {
          method: "GET", 
        }
      );
      if (!response.ok) throw new Error("Network response was not ok");
      setFeedbackMessage("Feedback Submitted");
      console.log("Success:", await response.json());
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="delivery-experience">
      <p className="experience-question">How was your Delivery Experience?</p>
      <div className="experience-options">
        {experiences.map((experience) => (
          <button
            key={experience.label}
            className={`experience-button ${
              selectedExperience === experience.label ? "selected" : ""
            }`}
            onClick={() => handleExperienceClick(experience)}
            disabled={
              isSelectionMade && selectedExperience !== experience.label
            }
            aria-label={experience.label}
          >
            {experience.emoji}
            <div className="experience-label">{experience.label}</div>
          </button>
        ))}
      </div>
      {feedbackMessage && <p className="feedback-message">{feedbackMessage}</p>}
    </div>
  );
};

export default DeliveryExperience;